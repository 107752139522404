import App from './App.vue'
import router from './router'
import { createApp } from 'vue'
import '@/styles/index.scss'
import { vMaska } from 'maska'
import screen from '@/utils/screen-variables'
import { MASKS, ROUTE_NAMES, FORM_RULES } from '@/constants'
import { useElementPlus } from '@/plugins/elementPlus'
import 'dayjs/locale/ru'
import '@/composables/usePermission/usePermission'

const app = createApp(App)

app.config.globalProperties.$screen = screen

app.config.globalProperties.$ROUTE_NAMES = ROUTE_NAMES

app.config.globalProperties.$MASKS = MASKS

app.config.globalProperties.$FORM_RULES = FORM_RULES

useElementPlus(app)

app.directive('maska', vMaska)

app.use(router)

app.mount('#app')
